import { CommonInputSegment } from "../../../Common/CommonInput";
import { useReactFlow } from "reactflow";
import { KnowledgeNodeData } from "../../Map/Nodes/KnowledgeNode";
import { Team } from "../../../../models/Team";
import { WorkflowVersion } from "../../../../models/Workflow";
import { useEffect, useState } from "react";
import { useKnowledgeService } from "../../../../contexts/KnowledgeContext";
import { WebsiteKnowledge } from "../../../../models/WebsiteKnowledge";
import { DocumentKnowledge } from "../../../../models/DocumentKnowledge";
import { KnowledgeAdvancedSettingsModal } from "./KnowledgeAdvancedSettingsModal";
import { CommonUnderlineButton } from "../../../Common/CommonUnderlineButton";
import VariableInputSelector from "../../Sidebar/VariableInputSelector";
import { AppPath } from "../../../../models/AppPath";
import { KnowledgeTestModal } from "./KnowledgeTestModal";
import AnimatedButton from "../../../AnimatedButton";

interface KnowledgeSourceSection {
  title: string;
  items: (WebsiteKnowledge | DocumentKnowledge)[];
  type: "website" | "document";
}

export const SidebarKnowledgeDetail: React.FC<{
  data: KnowledgeNodeData;
  team: Team;
  version: WorkflowVersion;
}> = ({ data, team, version }) => {
  const knowledgeService = useKnowledgeService();
  const { setNodes } = useReactFlow();
  const [availableWebsites, setAvailableWebsites] = useState<
    WebsiteKnowledge[]
  >([]);
  const [availableDocuments, setAvailableDocuments] = useState<
    DocumentKnowledge[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const [websites, documents] = await Promise.all([
          knowledgeService.websiteKnowledgeRepo.getList(
            knowledgeService.websiteKnowledgePath(team.id!)
          ),
          knowledgeService.documentKnowledgeRepo.getList(
            knowledgeService.documentKnowledgePath(team.id!)
          ),
        ]);

        setAvailableWebsites(websites);
        setAvailableDocuments(documents);

        // Clean up any stale sources
        const availableWebsiteIds = new Set(
          websites.map((website) => website.id)
        );
        const availableDocumentIds = new Set(documents.map((doc) => doc.id));

        const validWebsiteSources = data.websiteSources.filter((id) =>
          availableWebsiteIds.has(id)
        );
        const validDocumentSources = data.documentSources?.filter((id) =>
          availableDocumentIds.has(id)
        );

        if (
          validWebsiteSources.length !== data.websiteSources.length ||
          validDocumentSources?.length !== data.documentSources?.length
        ) {
          updateNode({
            websiteSources: validWebsiteSources,
            documentSources: validDocumentSources,
          });
        }
      } catch (error) {
        console.error("Error fetching knowledge sources:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSources();
  }, [team.id]);

  const updateNode = (newData: Partial<KnowledgeNodeData>) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === data.id) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  const handleSourceToggle = (id: string, type: "website" | "document") => {
    if (type === "website") {
      const newSources = data.websiteSources.includes(id)
        ? data.websiteSources.filter((source) => source !== id)
        : [...data.websiteSources, id];
      updateNode({ websiteSources: newSources });
    } else {
      const newSources = data.documentSources?.includes(id)
        ? data.documentSources.filter((source) => source !== id)
        : [...(data.documentSources ?? []), id];
      updateNode({ documentSources: newSources });
    }
  };

  const knowledgeSections: KnowledgeSourceSection[] = [
    {
      title: "Websites",
      items: availableWebsites,
      type: "website",
    },
    {
      title: "Documents",
      items: availableDocuments,
      type: "document",
    },
  ];

  const renderSourceItem = (
    item: WebsiteKnowledge | DocumentKnowledge,
    type: "website" | "document"
  ) => {
    const isWebsite = type === "website";
    const isSelected = isWebsite
      ? data.websiteSources.includes(item.id)
      : data.documentSources?.includes(item.id);

    return (
      <label
        key={item.id}
        className="flex items-start gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded-md"
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => handleSourceToggle(item.id, type)}
          className="mt-1 rounded border-gray-300 text-indigo-500 focus:ring-indigo-500"
        />
        <div className="flex flex-col">
          <span className="text-sm font-medium text-gray-700 truncate">
            {item.title}
          </span>
          <span className="text-xs text-gray-500 truncate">
            {isWebsite
              ? (item as WebsiteKnowledge).url
              : (item as DocumentKnowledge).title}
          </span>
        </div>
      </label>
    );
  };

  const availableVariables = Object.keys(version.usedVariables ?? {});
  const totalSelectedSources =
    data.websiteSources.length + (data.documentSources ?? []).length;

  return (
    <div className="flex flex-col gap-4">
      <KnowledgeTestModal
        shows={showTestModal}
        setShows={setShowTestModal}
        data={data}
        team={team}
        selectedWebsiteNames={[
          ...availableWebsites.map((w) => w.title ?? ""),
          ...availableDocuments.map((d) => d.title ?? ""),
        ]}
      />
      <AnimatedButton
        title="Preview Knowledge"
        buttonState={"ready"}
        setButtonState={() => console.log("")}
        onClick={() => setShowTestModal(true)}
        style="secondary"
        font="font-sans font-medium"
        leftIcon="play"
      />
      <KnowledgeAdvancedSettingsModal
        isOpen={showAdvancedSettings}
        onClose={() => setShowAdvancedSettings(false)}
        data={data}
        onUpdate={updateNode}
      />

      <CommonInputSegment
        title="Knowledge Step Title"
        onChange={(t) => updateNode({ title: t })}
        value={data.title}
        placeholder="Step Title"
        error={undefined}
        setError={undefined}
        id="knowledgeTitle"
        className="font-sans text-gray-700"
      />

      <div className="flex flex-col gap-2">
        <div className="text-sm font-medium text-gray-700">Search Query</div>
        <VariableInputSelector
          availableVariables={availableVariables}
          selectedInput={data.input}
          setSelectedInput={(input) => updateNode({ input })}
          currentNodeId={data.id}
          id="knowledgeQuery"
          includeMessages={true}
        />
        <div className="text-xs text-gray-500">
          Select the input to use as the search query for finding relevant
          knowledge
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <div className="text-sm font-medium text-gray-700">
            Select Knowledge Sources
          </div>
          <CommonUnderlineButton
            title="Advanced settings"
            onClick={() => setShowAdvancedSettings(true)}
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-24 bg-gray-50 rounded-md">
            <div className="text-sm text-gray-500">
              Loading knowledge sources...
            </div>
          </div>
        ) : availableWebsites.length === 0 &&
          availableDocuments.length === 0 ? (
          <div className="flex justify-center items-center h-24 bg-gray-50 rounded-md">
            <div className="text-sm text-gray-500">
              No knowledge sources available
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-3 bg-gray-50 rounded-md max-h-96 overflow-y-auto border border-gray-200">
            {knowledgeSections.map((section) => (
              <div key={section.type} className="flex flex-col gap-2">
                <div className="text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {section.title}
                </div>
                {section.items.length === 0 ? (
                  <div className="text-sm text-gray-500 italic">
                    No {section.title.toLowerCase()} available
                  </div>
                ) : (
                  section.items.map((item) =>
                    renderSourceItem(item, section.type)
                  )
                )}
              </div>
            ))}
          </div>
        )}

        <div className="flex flex-row justify-between">
          <div className="text-xs text-gray-400 mt-1">
            {totalSelectedSources} sources selected
          </div>
          <CommonUnderlineButton
            title="Add knowledge"
            onClick={() => window.open(AppPath.knowledge(team.id!), "_blank")}
          />
        </div>
      </div>
    </div>
  );
};
