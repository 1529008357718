import { useState } from "react";
import { PortalVersion } from "../../../models/PortalVersion";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { DemoChat } from "./DemoChat";
import { ChatMessage } from "../../../models/ChatMessage";
import { usePortalService } from "../../../contexts/PortalContext";
import { CommonCard } from "../../Common/CommonCard";
import FailureModal from "../../FailureModal";
import Icon from "../../Icon";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { v4 as uuidv4 } from "uuid";
import { DemoResponse } from "../../../services/PortalService";
import { useAnalytics } from "../../../contexts/AnalyticsContext";

type PortalDemoData = {
  type: "portal";
  version: PortalVersion;
};

type WorkflowDemoData = {
  type: "workflow";
  workflowId: string;
  versionId: string;
};

export const VersionDemoer: React.FC<{
  data: PortalDemoData | WorkflowDemoData;
  teamId: string;
  demoConfigVariables: { [key: string]: string };
  setDemoConfigVariables: (variables: { [key: string]: string }) => void;
  teamName: string;
  setSessionId?: (sessionId: string) => void;
}> = ({
  data,
  teamId,
  demoConfigVariables,
  setDemoConfigVariables,
  teamName,
  setSessionId,
}) => {
  const portalService = usePortalService();
  const workflowService = useWorkflowService();
  const analytics = useAnalytics();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [runState, setRunState] = useState<AnimationState>("ready");
  const [running, setIsRunning] = useState(false);
  const [configOpen, setConfigOpen] = useState(true);
  const [error, setError] = useState("");
  const [totalCost, setTotalCost] = useState(0);

  const variables = Object.keys(demoConfigVariables);

  const handleCancel = async () => {
    portalService.cancelCurrentStream();
    setRunState("ready");
    setIsRunning(false);
  };

  const handleRun = async (existingMessages: ChatMessage[]) => {
    if (running) {
      await handleCancel();
      return;
    }

    const tempMessage: ChatMessage = {
      content: "",
      role: "AI",
      createdAt: new Date().toISOString(),
      id: "TEMP",
    };

    setMessages([...existingMessages, tempMessage]);
    setIsRunning(true);
    analytics.track(
      `previewed${
        data.type[0].toUpperCase() + data.type.substr(1).toLowerCase()
      }`
    );

    let handleNewMessage = (demoResponse: DemoResponse) => {
      setMessages([
        ...existingMessages.filter((msg) => msg.id != "TEMP"),
        demoResponse.newMessage,
      ]);
      setTotalCost(demoResponse.totalCost);
    };
    const handleClose = () => {
      setRunState("success");
      setIsRunning(false);
    };
    const handleError = (error: Error) => {
      setRunState("ready");
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Unknown streaming error. Try again later.");
      }
    };
    try {
      if (data.type == "portal") {
        await portalService.streamDemo(
          teamId,
          data.version.prompt,
          demoConfigVariables,
          existingMessages,
          data.version.config,
          data.version.configId,
          handleNewMessage,
          handleClose,
          handleError
        );
      } else {
        const sessionId = uuidv4();
        setSessionId?.(sessionId);

        await workflowService.streamDemo(
          teamId,
          data.workflowId,
          data.versionId,
          demoConfigVariables,
          existingMessages,
          sessionId,
          handleNewMessage,
          handleClose,
          handleError
        );
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Unknown streaming error. Try again later.");
      }
      setRunState("ready");
    }
  };

  const handleSendMessage = async (message: string) => {
    const tempUrl = URL.createObjectURL(new Blob());
    const uuid = tempUrl.toString();
    URL.revokeObjectURL(tempUrl);
    const id = uuid.split("/")[3];
    const newMessage: ChatMessage = {
      content: message,
      role: "USER",
      createdAt: new Date().toISOString(),
      id,
    };
    const all = [...messages, newMessage];
    await handleRun(all);
  };

  return (
    <CommonCard className="h-full flex flex-col">
      <FailureModal
        title="Demo Error"
        message={error}
        shows={error != ""}
        closed={() => setError("")}
        backButtonTitle="Back"
      />
      <div className="flex flex-col gap-4 h-full">
        <div className="flex flex-col gap-2 rounded-lg">
          <div className="flex flex-row justify-between">
            <div className="text-sm font-sans font-medium text-gray-700">
              {data.type == "portal" ? "Prompt Preview" : "Agent Preview"}
            </div>
            <div className="text-xs text-gray-500">{`Total Cost: ${totalCost.toFixed(
              5
            )} USD`}</div>
          </div>
          <div
            id="demoProfile"
            className={`${
              variables.length == 0 ? "hidden" : ""
            } flex flex-col gap-2 p-2 bg-blue-25 rounded-lg ${
              configOpen ? "" : "max-h-12"
            }`}
          >
            <div
              className={`flex items-center flex-row gap-2 pt-1 pb-2 border-blue-50 justify-between ${
                configOpen ? "border-b" : ""
              }`}
              onClick={() => setConfigOpen(!configOpen)}
            >
              <div className="flex  justify-start items-start flex-col gap-2">
                <div
                  className={`transition-all duration-200 font-medium font-sans ${
                    configOpen
                      ? "text-sm text-gray-700"
                      : "text-base text-blue-600"
                  }`}
                >
                  Variables
                </div>
                {configOpen && (
                  <div
                    className={`text-gray-500 text-xs pr-2 transition-all duration-200`}
                  >
                    Use in previewing
                  </div>
                )}
              </div>
              <Icon
                type="chevron"
                className={`size-7 text-blue-600 transition-all duration-200 ${
                  configOpen ? "" : "rotate-180"
                }`}
              />
            </div>
            {variables.length == 0 && (
              <div className="text-xs w-full text-gray-400">
                When you add variables to your prompt they'll appear here
              </div>
            )}
            <div
              className={`grid grid-cols-3 gap-2 max-h-44 ${
                configOpen ? "overflow-y-scroll" : "h-0 overflow-clip"
              }`}
            >
              {variables.map((variable, index) => {
                return (
                  <div className="w-full" key={variable}>
                    <VariableInput
                      setNewValue={(value) =>
                        setDemoConfigVariables({
                          ...demoConfigVariables,
                          [variable]: value,
                        })
                      }
                      value={demoConfigVariables[variable]}
                      title={variable}
                      id={`variable${index + 1}`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <AnimatedButton
          title={`${running ? "Stop" : "Preview Version"}`}
          onClick={() => handleRun([])}
          buttonState={runState}
          setButtonState={setRunState}
          style={`${running ? "secondary" : "action"}`}
          key="run"
          leftIcon={`${running ? "pause" : "play"}`}
          classNameIn="fill-gray-0"
          font="font-sans font-medium"
          id="runDemoButton"
        />

        <div className="flex-grow overflow-hidden">
          <DemoChat
            messages={messages}
            running={running}
            teamName={teamName}
            sendMessage={handleSendMessage}
          />
        </div>
      </div>
    </CommonCard>
  );
};

export const VariableInput: React.FC<{
  title: string;
  value: string;
  setNewValue: (value: string) => void;
  id?: string;
  className?: string;
}> = ({ title, value, setNewValue, id, className }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className={`text-xs font-medium font-sans text-gray-600`}>
        {title}
      </div>
      <input
        className={`border-gray-300 text-sm border-1 bg-gray-0 rounded-md py-2 px-3 leading-tight font-normal text-slate-900 placeholder-gray-400 ${className}`}
        type="text"
        placeholder="Enter value here"
        value={value}
        onChange={(value) => setNewValue(value.target.value)}
        id={id}
      />
    </div>
  );
};
