import React, { useCallback, useEffect, useState } from "react";
import CommonContainer from "../Common/CommonContainer";
import { useParams, useSearchParams } from "react-router-dom";
import { useWorkflowService } from "../../contexts/WorkflowContext";
import { Workflow, WorkflowVersion } from "../../models/Workflow";
import { SelectableNodeData, WorkflowMap } from "./Map/WorkflowMap";
import { CommonLoading } from "../Common/CommonLoading";
import { Sidebar } from "./Sidebar/Sidebar";
import { Team } from "../../models/Team";
import { useTeams } from "../../contexts/TeamContext";
import { ReactFlowProvider } from "reactflow";
import { usePortalService } from "../../contexts/PortalContext";
import { ChatConfigurationTemplate } from "../../models/ChatConfigurationTemplate";
import { FloatingBreadcrumb } from "./FloatingBreadcrumb";
import { AppPath } from "../../models/AppPath";
import FailureModal from "../FailureModal";
import { useDebounce } from "../../utils/Debounce";

interface WorkflowsProps {}

export const TemplatesContext = React.createContext<
  ChatConfigurationTemplate[]
>([]);

export const WorkflowDetail: React.FC<WorkflowsProps> = ({}) => {
  const { teamId, workflowId } = useParams<{
    teamId: string;
    workflowId: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const workflowService = useWorkflowService();
  const teamService = useTeams();
  const portalService = usePortalService();

  const [workflow, setWorkflow] = useState<Workflow>();
  const [version, setVersion] = useState<WorkflowVersion>();
  const [selectedNodeData, setSelectedNodeData] =
    useState<SelectableNodeData>();
  const [team, setTeam] = useState<Team>();
  const [templates, setTemplates] = useState<ChatConfigurationTemplate[]>();
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadData = async () => {
      const fetchWorkflow = workflowService.workflowRepo.get(
        workflowService.workflowPath(teamId!),
        workflowId!
      );
      const fetchTeam = teamService.teamRepo.get(
        teamService.teamPath(),
        teamId!
      );
      const getTempaltes = portalService.configTemplateRepo.getList(
        portalService.configTemplatePath()
      );
      const getWorkflows = workflowService.workflowRepo.getList(
        workflowService.workflowPath(teamId!)
      );

      try {
        const [
          fetchedWorkflow,
          fetchedTeam,
          fetchedTemplates,
          fetchedWorkflows,
        ] = await Promise.all([
          fetchWorkflow,
          fetchTeam,
          getTempaltes,
          getWorkflows,
        ]);

        if (!fetchedWorkflow || !fetchedTeam) {
          return;
        }

        // Get version ID from URL parameter or fall back to current version
        const versionId =
          searchParams.get("version") || fetchedWorkflow.currentVersionId;

        if (!versionId) {
          setError("No version available");
          return;
        }

        // Update URL if needed
        if (!searchParams.get("version") && versionId) {
          setSearchParams(
            {
              ...Object.fromEntries(searchParams),
              version: versionId,
            },
            { replace: true }
          );
        }

        const workflowVersion = await workflowService.workflowVersionRepo.get(
          workflowService.workflowVersionPath(teamId!, workflowId!),
          versionId
        );

        if (!workflowVersion) {
          setError("Version not found");
          return;
        }

        setVersion(workflowVersion);
        setWorkflow(fetchedWorkflow);
        setTeam(fetchedTeam);
        setTemplates(fetchedTemplates);
        setWorkflows(fetchedWorkflows);
        setLoading(false);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Something went wrong");
      }
    };

    loadData();
  }, [workflowService, searchParams]);

  const handleTeamUpdate = async (team: Team) => {
    setTeam(team);
    await teamService.teamRepo.update(team, teamService.teamPath(), teamId!);
  };

  const updateWorkflow = async (newWorkflow: Workflow) => {
    const updatedWorkflow: Workflow = {
      ...newWorkflow,
      modifiedAt: new Date(),
    };
    setWorkflow(updatedWorkflow);
    await workflowService.workflowRepo.update(
      updatedWorkflow,
      workflowService.workflowPath(teamId!),
      workflowId!
    );
  };

  const updateWorkflowVersion = useCallback(
    async (newWorkflowVersion: WorkflowVersion) => {
      const updatedWorkflowVersion: WorkflowVersion = {
        ...newWorkflowVersion,
        modifiedAt: new Date(),
      };

      await workflowService.updateVersion(
        updatedWorkflowVersion,
        newWorkflowVersion.id,
        workflowId!,
        teamId!
      );

      setVersion(updatedWorkflowVersion);
    },
    [workflowService, workflowId, teamId]
  );

  const updateMap = useCallback(
    async (uiString: string) => {
      const updatedWorkflowVersion: WorkflowVersion = {
        ...version!,
        uiDataString: uiString,
        modifiedAt: new Date(),
      };

      await workflowService.updateVersion(
        updatedWorkflowVersion,
        version!.id,
        workflowId!,
        teamId!
      );

      setVersion(updatedWorkflowVersion);
    },
    [workflowService, workflowId, teamId, version]
  );

  const sidebarUpdateWorkflowDebounce = useDebounce(
    async (newWorkflow: Workflow) => {
      await updateWorkflow(newWorkflow);
    },
    1000
  );

  const mapUpdateWorkflowVersionDebounce = useDebounce(
    async (uiDataString: string) => {
      await updateMap(uiDataString);
    },
    3000
  );

  const sidebarUpdateWorkflowVersionDebounce = useDebounce(
    async (newWorkflowVersion: WorkflowVersion) => {
      await updateWorkflowVersion(newWorkflowVersion);
    },
    1000
  );

  const handleDataStringUpdate = async (uiDataString: string) => {
    mapUpdateWorkflowVersionDebounce(uiDataString);
  };

  const handleSetWorkflow = (workflow: Workflow) => {
    sidebarUpdateWorkflowDebounce(workflow);
  };

  const handleVersionUpdate = (workflowVersion: WorkflowVersion) => {
    if (workflowVersion.id != version?.id) {
      setVersion(workflowVersion);
      // Update URL if needed
      setSearchParams(
        {
          ...Object.fromEntries(searchParams),
          version: workflowVersion.id,
        },
        { replace: true }
      );
    } else {
      sidebarUpdateWorkflowVersionDebounce(workflowVersion);
    }
  };

  return (
    <CommonContainer className="!p-0 relative overflow-x-hidden">
      <FailureModal
        message={error}
        shows={error != ""}
        closed={() => setError("")}
      />
      {loading && <CommonLoading />}
      {workflow && version && team && templates && !loading && (
        <ReactFlowProvider>
          <TemplatesContext.Provider value={templates}>
            <div className="flex w-full h-full">
              <div className="flex w-full h-full relative">
                <WorkflowMap
                  saveMap={handleDataStringUpdate}
                  workflow={workflow}
                  teamId={teamId!}
                  selectedNode={(data) => {
                    setSelectedNodeData(data);
                  }}
                  version={version}
                />
                <div className="absolute flex top-6 left-4">
                  <FloatingBreadcrumb
                    teamId={teamId!}
                    sections={[
                      { name: "Agents", link: AppPath.workflows(teamId!) },
                      {
                        name: `${workflow?.name}`,
                        link: ``,
                      },
                      {
                        name: `${version?.name}`,
                        link: ``,
                      },
                    ]}
                  />
                </div>
              </div>
              <Sidebar
                workflow={workflow}
                selectedNodeData={selectedNodeData}
                team={team}
                setTeam={handleTeamUpdate}
                setWorkflow={handleSetWorkflow}
                templates={templates}
                workflows={workflows}
                workflowVersion={version}
                setWorkflowVersion={handleVersionUpdate}
              />
            </div>
          </TemplatesContext.Provider>
        </ReactFlowProvider>
      )}
    </CommonContainer>
  );
};
