import React, { useState } from "react";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useTeams } from "../../contexts/TeamContext";
import Icon from "../Icon";
import { CommonInputSegment } from "../Common/CommonInput";
import { CommonModal } from "../Common/CommonModal";
interface TokenCreationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  teamId: string;
}

export const TokenCreationModal: React.FC<TokenCreationModalProps> = ({
  isOpen,
  onDismiss,
  teamId,
}) => {
  const [step, setStep] = useState<"name" | "display">("name");
  const [tokenName, setTokenName] = useState("");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const teamService = useTeams();

  const handleCreateToken = async () => {
    if (!tokenName.trim()) {
      setError("Token name is required");
      return;
    }

    setButtonState("loading");
    try {
      const newToken = await teamService.createNewToken(teamId, tokenName);
      setToken(newToken);
      setStep("display");
      setButtonState("success");
    } catch (err) {
      setButtonState("error");
      setError("Failed to create token. Please try again.");
    }
  };

  const handleCopyAndClose = () => {
    navigator.clipboard.writeText(token);
    setButtonState("success");
    setTimeout(() => {
      onDismiss();
      setStep("name");
      setTokenName("");
      setToken("");
      setError("");
      setButtonState("ready");
    }, 1000);
  };

  const modalContent = (
    <div className="w-96 p-6 flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <h2 className="text-xl font-medium font-gooper text-left text-gray-700">
          {step === "name"
            ? "Create New Access Token"
            : "Access Token Successfully Created"}
        </h2>
        <button onClick={onDismiss}>
          <Icon type="x-no-circle" className="size-4 text-gray-500" />
        </button>
      </div>

      <div className="border-b" />

      {step === "name" ? (
        <>
          <CommonInputSegment
            title="Token Name"
            subtitle="Give your token a descriptive name to help you identify it later"
            value={tokenName}
            onChange={setTokenName}
            error={error}
            setError={setError}
            placeholder="e.g., Production API Key"
            type="text"
            id="tokenNameInput"
          />
          <AnimatedButton
            title="Create Token"
            onClick={handleCreateToken}
            buttonState={buttonState}
            setButtonState={setButtonState}
            style="action"
            font="font-sans"
            id="modalCreateButton"
          />
        </>
      ) : (
        <>
          <p className="text-gray-800 text-sm">
            Use this in your application to securely connect to the Easybeam
            API.
          </p>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-500">API key</div>
            <p
              id="tokenText"
              className="p-2 bg-gray-50 border-gray-300 border rounded text-left text-slate-900 text-xs break-words whitespace-pre-wrap font-gooper font-medium"
            >
              {token}
            </p>
            <div className="text-sm font-gooper text-gray-400">
              This will only be displayed once.
            </div>
          </div>
          <AnimatedButton
            title="Copy to Clipboard & Close"
            onClick={handleCopyAndClose}
            buttonState={buttonState}
            setButtonState={setButtonState}
            style="action"
            font="font-sans"
            id="confirmButton"
            classNameIn={
              buttonState === "ready" ? "border border-blue-500" : ""
            }
          />
        </>
      )}
    </div>
  );

  return (
    <CommonModal isOpen={isOpen} onDismiss={onDismiss}>
      {modalContent}
    </CommonModal>
  );
};

export default TokenCreationModal;
