import { Repository } from "../repos/Repository";
import { WebsiteKnowledge } from "../models/WebsiteKnowledge";
import { Networking } from "../utils/NetworkHelper";
import { KnowledgeQueryConfig, WebsiteFilterOptions } from "../models/Workflow";
import { ChunkEncodingStrategy } from "../models/ChunkEncodingStrategy";
import { DocumentKnowledge } from "../models/DocumentKnowledge";

interface AddWebsiteKnowledgeRequest {
  url: string;
  filterOptions: WebsiteFilterOptions;
  encoding: ChunkEncodingStrategy;
}

export interface KnowledgeService {
  websiteKnowledgeRepo: Repository<WebsiteKnowledge>;
  websiteKnowledgePath(teamId: string): string;
  documentKnowledgeRepo: Repository<DocumentKnowledge>;
  documentKnowledgePath(teamId: string): string;
  addWebsiteKnowledge(
    teamId: string,
    data: AddWebsiteKnowledgeRequest
  ): Promise<WebsiteKnowledge>;
  removeWebsiteKnowledge(teamId: string, id: string): Promise<void>;
  previewKnowledge(
    teamId: string,
    input: string,
    queryConfig: KnowledgeQueryConfig,
    websiteSources: string[],
    documentSources: string[]
  ): Promise<string>;

  addDocumentKnowledge(
    teamId: string,
    data: FormData
  ): Promise<DocumentKnowledge>;
  removeDocumentKnowledge(teamId: string, id: string): Promise<void>;
}

export class FirestoreKnowledgeService implements KnowledgeService {
  constructor(
    public websiteKnowledgeRepo: Repository<WebsiteKnowledge>,
    public documentKnowledgeRepo: Repository<DocumentKnowledge>,

    private networkHelper: Networking
  ) {}

  websiteKnowledgePath(teamId: string): string {
    return `teams/${teamId}/websiteKnowledge`;
  }
  documentKnowledgePath(teamId: string): string {
    return `teams/${teamId}/documentKnowledge`;
  }

  async addWebsiteKnowledge(
    teamId: string,
    data: AddWebsiteKnowledgeRequest
  ): Promise<WebsiteKnowledge> {
    const response = await this.networkHelper.sendRequest(
      `/teams/${teamId}/knowledge/websites`,
      "POST",
      data
    );
    const json = await response.json();

    return json as WebsiteKnowledge;
  }

  async removeWebsiteKnowledge(teamId: string, id: string): Promise<void> {
    const sourceId = id.split("/").pop();

    await this.networkHelper.sendRequest(
      `/teams/${teamId}/knowledge/websites/${sourceId}`,
      "DELETE"
    );
  }

  async previewKnowledge(
    teamId: string,
    input: string,
    queryConfig: KnowledgeQueryConfig,
    websiteSources: string[],
    documentSources: string[]
  ): Promise<string> {
    const serverResponse = await this.networkHelper.sendRequest(
      "/preview/knowledge",
      "POST",
      {
        input,
        teamId,
        queryConfig,
        websiteSources,
        documentSources,
      }
    );
    const { response } = await serverResponse.json();
    return response;
  }

  async addDocumentKnowledge(
    teamId: string,
    data: FormData
  ): Promise<DocumentKnowledge> {
    const response = await this.networkHelper.sendFormData(
      `/teams/${teamId}/knowledge/documents`,
      "POST",
      data
    );
    const json = await response.json();

    return json as DocumentKnowledge;
  }

  async removeDocumentKnowledge(teamId: string, id: string): Promise<void> {
    const sourceId = id.split("/").pop();

    await this.networkHelper.sendRequest(
      `/teams/${teamId}/knowledge/documents/${sourceId}`,
      "DELETE"
    );
  }
}
