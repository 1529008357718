import React, { ReactNode, useEffect } from "react";
import { Node, useReactFlow, Edge } from "reactflow";
import { PortalBadge, PortalNodeData, PortalNodeType } from "./PortalNode";
import { WorkflowAddNodeEdgeType } from "../Edges/WorkflowAddNodeEdge";
import Icon from "../../../Icon";
import {
  DecisionBadge,
  DecisionNodeData,
  DecisionNodeType,
} from "./DecisionNode";
import { WebsiteBadge, WebsiteNodeData, WebsiteNodeType } from "./WebsiteNode";
import { SearchNodeData, SearchNodeType } from "./SearchNode";
import { WorkflowNodeData, WorkflowNodeType } from "./WorkflowNode";
import { APIBadge, APINodeData, APINodeType } from "./APINode";
import { ActionBadge, ActionNodeData, ActionNodeType } from "./ActionNode";
import { extractDefaultConfig } from "../../../../utils/ExtractDefaultChatConfig";
import { TemplatesContext } from "../../WorkflowDetail";
import {
  KnowledgeBadge,
  KnowledgeNodeData,
  KnowledgeNodeType,
} from "./KnowledgeNode";
import { useNodeZoom } from "../../../../utils/ZoomToNode";
import { useAnalytics } from "../../../../contexts/AnalyticsContext";

export const SelectorNodeType = "workflowNodeSelectorNode";

export const createSelectorNode = (
  source: Node<any>,
  teamId: string,
  targetId?: string,
  parentEdge?: string,
  handleId?: string,
  replaceNodeId?: string
): Node<SelectorNodeData> => {
  const newSelectionNode: Node<SelectorNodeData> = {
    id: SelectorNodeType,
    type: SelectorNodeType,
    position: {
      x: source.position.x - 80,
      y: source.position.y + (source.height ?? 0) * 1.1,
    },
    data: {
      parentId: source.id,
      teamId,
      targetId,
      parentEdge,
      handleId,
      replaceNodeId,
    },
    draggable: false,
    zIndex: 1000,
  };
  return newSelectionNode;
};

export interface SelectorNodeData {
  parentId: string;
  targetId?: string;
  teamId: string;
  parentEdge?: string;
  handleId?: string;
  replaceNodeId?: string;
}

export interface SelectorNodeProps {
  data: SelectorNodeData;
  id: string;
}

type NodeType =
  | typeof PortalNodeType
  | typeof DecisionNodeType
  | typeof WebsiteNodeType
  | typeof SearchNodeType
  | typeof WorkflowNodeType
  | typeof APINodeType
  | typeof ActionNodeType
  | typeof KnowledgeNodeType;

type NodeData =
  | PortalNodeData
  | DecisionNodeData
  | WebsiteNodeData
  | SearchNodeData
  | WorkflowNodeData
  | APINodeData
  | ActionNodeData
  | KnowledgeNodeData;

export const SelectorNode: React.FC<SelectorNodeProps> = ({ data, id }) => {
  const { setNodes, getNode, setEdges, getEdges } = useReactFlow();

  const analytics = useAnalytics();

  const { zoomToNode } = useNodeZoom({ padding: 0.6 });

  const templates = React.useContext(TemplatesContext);

  useEffect(() => {
    setTimeout(() => {
      zoomToNode(id);
    }, 50);
  }, []);

  const nodeData = (nodeType: NodeType): NodeData => {
    const prefix = `node_${nodeType}`;
    const newNodeId = `${prefix}${Math.random()}`;
    switch (nodeType) {
      case KnowledgeNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: KnowledgeNodeType,
          title: "Knowledge Step",
          websiteSources: [],
          documentSources: [],
          resultCount: 3,
          contextWindow: 1,
          similarityThreshold: 0.5,
          input: { type: "messages", messageType: "mostRecent" },
        };
      case APINodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: APINodeType,
          title: "API Step",
          method: "GET",
          url: "",
          headers: {},
        };
      case PortalNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: PortalNodeType,
          title: "Prompt Step",
          includeMessages: true,
        };
      case DecisionNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: DecisionNodeType,
          title: "Decision Step",
          selectedOperator: "==",
        };
      case WebsiteNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: WebsiteNodeType,
          title: "Website Step",
          url: "",
          filterOptions: {
            removeScripts: true,
            removeStyles: true,
            removeNavigation: false,
            removeFooter: false,
            removeAds: true,
            keepMainContent: false,
            customSelectors: [],
          },
        };
      case SearchNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: SearchNodeType,
          title: "Search Step",
        };
      case WorkflowNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: WorkflowNodeType,
          title: "Workflow Step",
        };
      case ActionNodeType:
        return {
          id: newNodeId,
          teamId: data.teamId,
          type: ActionNodeType,
          title: "Action Step",
          actions: [],
          providerId: "openai",
          providerConfig: extractDefaultConfig(
            templates.filter((t) => t.id == "openai")[0]
          ),
        };
    }
  };

  const createNewNode = (nodeType: NodeType, parent: Node): Node<NodeData> => {
    const newData = nodeData(nodeType);
    let xAdjustment = 0;
    if (data.handleId) {
      if (data.handleId.includes("_success")) {
        xAdjustment = -120;
      } else if (data.handleId.includes("_false")) {
        xAdjustment = 160;
      } else {
        let totalActionsCount = 0;
        if (parent.data.type === ActionNodeType) {
          totalActionsCount = parent.data.actions.length;
          const indexMatch = data.handleId?.match(/INDEX:(\d+)/);
          if (indexMatch) {
            const currentIndex = parseInt(indexMatch[1]);
            xAdjustment = (totalActionsCount - currentIndex) * 240;
          }
        }
      }
    }

    return {
      id: newData.id,
      type: nodeType,
      position: {
        x: parent.position.x + xAdjustment,
        y: parent.position.y + (parent.height ?? 0) + 180,
      },
      selected: true,
      data: newData,
    };
  };

  const handleCreate = (nodeType: NodeType) => {
    const parent = getNode(data.parentId);
    if (!parent) {
      console.error("No parent for selector node");
      return;
    }

    analytics.track("createdAgentStep", { stepType: nodeType });

    const newNode = createNewNode(nodeType, parent);

    if (data.replaceNodeId) {
      // We're replacing an existing node
      const nodeToReplace = getNode(data.replaceNodeId);
      if (!nodeToReplace) return;

      // Get all edges connected to the node being replaced
      const edges = getEdges();
      const incomingEdges = edges.filter(
        (edge) => edge.target === data.replaceNodeId
      );
      const outgoingEdges = edges.filter(
        (edge) => edge.source === data.replaceNodeId
      );

      // Create new edges for the replacement node
      const newEdges = [
        ...incomingEdges.map((edge) => ({
          ...edge,
          id: `reactflow__edge-${edge.source}${edge.sourceHandle}-${newNode.id}${newNode.id}_target`,
          target: newNode.id,
          targetHandle: `${newNode.id}_target`,
        })),
        ...outgoingEdges.map((edge) => ({
          ...edge,
          id: `reactflow__edge-${newNode.id}${newNode.id}_source-${edge.target}${edge.targetHandle}`,
          source: newNode.id,
          sourceHandle: `${newNode.id}_source`,
        })),
      ];

      // Update nodes and edges
      setNodes((nodes) => [
        ...nodes.filter(
          (node) =>
            node.id !== SelectorNodeType && node.id !== data.replaceNodeId
        ),
        {
          ...newNode,
          position: nodeToReplace.position, // Keep the same position as the replaced node
        },
      ]);

      setEdges((edges) => [
        ...edges.filter(
          (edge) =>
            edge.source !== data.replaceNodeId &&
            edge.target !== data.replaceNodeId
        ),
        ...newEdges,
      ]);
    } else if (data.targetId) {
      const targetNode = getNode(data.targetId);
      if (targetNode) {
        setNodes((nodes) => {
          const updatedNodes = nodes.map((node) => {
            if (
              node.id === data.targetId ||
              node.position.y > targetNode.position.y
            ) {
              return {
                ...node,
                position: {
                  ...node.position,
                  y: node.position.y + 300,
                },
              };
            }
            return node;
          });
          return [
            ...updatedNodes.filter((node) => node.id !== SelectorNodeType),
            newNode,
          ];
        });

        // replacement
        const newEdges: Edge[] = [
          {
            id: `reactflow__edge-${parent.id}${data.handleId}-${newNode.id}${newNode.id}_target`,
            source: parent.id,
            sourceHandle: data.handleId,
            target: newNode.id,
            targetHandle: `${newNode.id}_target`,
            type: WorkflowAddNodeEdgeType,
          },
          {
            id: `reactflow__edge-${newNode.id}${newNode.id}_source-${data.targetId}${data.targetId}_target`,
            source: newNode.id,
            sourceHandle: `${newNode.id}_source`,
            target: data.targetId,
            targetHandle: `${data.targetId}_target`,
            type: WorkflowAddNodeEdgeType,
          },
        ];

        setEdges((edges) =>
          [...edges, ...newEdges].filter((edge) => edge.id !== data.parentEdge)
        );
      }
    } else {
      setNodes((nodes) => [
        ...nodes.filter((node) => node.id !== SelectorNodeType),
        newNode,
      ]);

      const isFromAction = data.handleId?.includes("INDEX") == true;

      const newEdge: Edge = {
        id: `reactflow__edge-${parent.id}${data.handleId}-${newNode.id}${newNode.id}_target`,
        source: parent.id,
        sourceHandle: data.handleId,
        target: newNode.id,
        targetHandle: `${newNode.id}_target`,
        type: WorkflowAddNodeEdgeType,
        zIndex: isFromAction ? 2000 : undefined,
      };

      setEdges((edges) => [...edges, newEdge]);
    }
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className="bg-white shadow-lg rounded-lg w-[400px] flex flex-col p-4 gap-2">
        <SelectionButton
          id={`promptNodeButton`}
          onClick={() => handleCreate(PortalNodeType)}
          buttonClassName="hover:bg-lime-50"
          badge={<PortalBadge />}
        />
        <SelectionButton
          id={`decisionNodeButton`}
          onClick={() => handleCreate(DecisionNodeType)}
          buttonClassName="hover:bg-orange-50"
          badge={<DecisionBadge />}
        />
        <SelectionButton
          id={`apiNodeButton`}
          onClick={() => handleCreate(APINodeType)}
          buttonClassName="hover:bg-emerald-50"
          badge={<APIBadge />}
        />
        <SelectionButton
          id={`actionNodeButton`}
          onClick={() => handleCreate(ActionNodeType)}
          buttonClassName="hover:bg-yellow-50"
          badge={<ActionBadge />}
        />
        <SelectionButton
          id={`websiteNodeButton`}
          onClick={() => handleCreate(WebsiteNodeType)}
          buttonClassName="hover:bg-purple-50"
          badge={<WebsiteBadge />}
        />
        <SelectionButton
          id={`knowledgeNodeButton`}
          onClick={() => handleCreate(KnowledgeNodeType)}
          buttonClassName="hover:bg-indigo-50"
          badge={<KnowledgeBadge />}
        />
      </div>
    </div>
  );
};

const SelectionButton: React.FC<{
  onClick: () => void;
  buttonClassName: string;
  badge: ReactNode;
  comingSoon?: boolean;
  id: string;
}> = ({ onClick, buttonClassName, comingSoon, badge, id }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`flex flex-row px-3 py-2 justify-between items-center rounded-lg bg-gray-50 ${buttonClassName} testId-${id}`}
    >
      {badge}
      <div className="flex flex-row justify-between items-center gap-1">
        {comingSoon == true && (
          <div className="h-[22px] px-1 py-0.5 bg-[#eaefff] rounded justify-start items-center gap-1 inline-flex">
            <div className="text-center text-[#3062ff] text-xs font-medium font-['General Sans'] leading-[18px]">
              Coming Soon
            </div>
          </div>
        )}

        <Icon type="chevron" className="text-gray-400 size-8 rotate-90" />
      </div>
    </button>
  );
};
