import React from "react";
import { useReactFlow } from "reactflow";
import Icon from "../../../Icon";
import { createSelectorNode } from "./SelectorNode";
import { AddNodeHandler } from "../Handlers/AddNodeHandler";
import { SelectedInput } from "../../Sidebar/VariableInputSelector";

export const KnowledgeNodeType = "workflowKnowledgeNodeType";

export interface KnowledgeNodeData {
  id: string;
  teamId: string;
  type: typeof KnowledgeNodeType;
  title: string;
  websiteSources: string[];
  documentSources?: string[];
  input: SelectedInput;

  // Query parameters
  contextWindow: number;
  resultCount: number;
  similarityThreshold: number;
}

export interface KnowledgeNodeParams {
  data: KnowledgeNodeData;
}

export const KnowledgeNode: React.FC<KnowledgeNodeParams> = ({ data }) => {
  const { getNode, setNodes, getEdges } = useReactFlow();

  const handleAdd = () => {
    const currentNode = getNode(data.id);
    if (!currentNode) return;

    const selectionNode = createSelectorNode(currentNode, data.teamId);
    setNodes((nds) => nds.concat(selectionNode));
  };

  const sourceConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.source === data.id);
  };

  const targetConnected = () => {
    const edges = getEdges();
    return edges.some((edge) => edge.target === data.id);
  };

  const selected = getNode(data.id)?.selected;
  const nodeClasses = `shadow group box-border border-2 ${
    selected ? "border-blue-500" : "border-transparent hover:border-blue-500"
  } flex flex-col gap-1 rounded-lg p-3 bg-gray-0 w-56 transition-all`;

  return (
    <div className={nodeClasses}>
      <AddNodeHandler
        type="target"
        isConnected={targetConnected()}
        id={`${data.id}_target`}
      />
      <div className="flex flex-row justify-between h-6">
        <KnowledgeBadge />
      </div>
      <div className="text-2xl font-gooper text-gray-900 h-12 truncate">
        {data.title}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-sm text-gray-500 truncate">
          {data.websiteSources.length} Sources
        </div>
        <div className="text-xs text-gray-400 truncate">
          {data.resultCount} Results
        </div>
      </div>
      <AddNodeHandler
        onClick={handleAdd}
        type="source"
        isConnected={sourceConnected()}
        id={`${data.id}_source`}
      />
    </div>
  );
};

export const KnowledgeBadge: React.FC = () => {
  return (
    <div className="px-2.5 py-0.5 rounded justify-center items-center gap-1 flex bg-indigo-50 text-indigo-400">
      <Icon type="book-open" className="size-3" />
      <div className="text-center text-xs font-medium leading-[18px]">
        Knowledge
      </div>
    </div>
  );
};
