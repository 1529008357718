import React, { useState, useEffect } from "react";
import AnimatedButton, { AnimationState } from "../../AnimatedButton";
import { CommonModal } from "../../Common/CommonModal";
import Icon from "../../Icon";
import { CommonInputSegment } from "../../Common/CommonInput";
import { CommonSpinner } from "../../Common/CommonLoading";
import { useDebounce } from "../../../utils/Debounce";
import { formatDate, formatTime } from "../../../utils/DateUtils";
import { SoloAgent } from "../../../models/SoloAgent";
import { useWorkflowService } from "../../../contexts/WorkflowContext";
import { useTeams } from "../../../contexts/TeamContext";
import HexColorInputSegment from "./HexColorInput";

interface PublishSoloAgentModalProps {
  shows: boolean;
  setShows: (shows: boolean) => void;
  agentId: string;
  teamId: string;
}

export const PublishSoloAgentModal: React.FC<PublishSoloAgentModalProps> = ({
  shows,
  setShows,
  agentId,
  teamId,
}) => {
  const workflowService = useWorkflowService();
  const teamService = useTeams();

  const [publishState, setPublishState] = useState<AnimationState>("ready");
  const [createState, setCreateState] = useState<AnimationState>("ready");
  const [deleteState, setDeleteState] = useState<AnimationState>("ready");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [agent, setAgent] = useState<SoloAgent | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const handleSave = useDebounce(async (updatedAgent: SoloAgent) => {
    try {
      await workflowService.updateSoloAgent(updatedAgent, agentId, teamId);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Failed to save changes");
      }
    }
  }, 2000);

  useEffect(() => {
    const fetchAgent = async () => {
      if (!shows) return;

      setIsLoading(true);
      try {
        const agent = await workflowService.agentRepo.get(
          workflowService.agentPath(),
          agentId
        );
        setAgent(agent || undefined);
      } catch (error) {
        console.error("Failed to fetch agent:", error);
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAgent();
  }, [shows, agentId, teamId, workflowService]);

  const handleDelete = async () => {
    if (!agent) return;

    setDeleteState("loading");
    try {
      await workflowService.deleteSoloAgent(teamId, agentId);
      setDeleteState("success");
      handleDismiss();
    } catch (error) {
      setDeleteState("error");
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  const handleCreate = async () => {
    setCreateState("loading");
    try {
      const token = await teamService.createNewToken(
        teamId,
        `token for ${agent?.title ?? "Agent"}`
      );
      const newAgent = await workflowService.createSoloAgent(
        token,
        agentId,
        teamId
      );
      setAgent(newAgent.agent);
      setCreateState("success");
    } catch (error) {
      setCreateState("error");
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  const handlePublish = async () => {
    if (!agent) return;

    setPublishState("loading");
    try {
      // don't fight the debounce for the save
      await new Promise((resolve) => setTimeout(resolve, 3000));

      const publishedAgent = await workflowService.publishSoloAgent(
        agentId,
        teamId
      );
      setAgent(publishedAgent);
      setPublishState("success");
    } catch (error) {
      setPublishState("error");
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  };

  const handleDismiss = () => {
    setShows(false);
    setAgent(undefined);
    setError("");
  };

  const handleInputChange = (field: keyof SoloAgent, value: string) => {
    const updatedAgent = { ...agent!, [field]: value };
    setAgent(updatedAgent);
    handleSave(updatedAgent);
  };

  if (!shows) return null;

  return (
    <CommonModal isOpen={shows} onDismiss={handleDismiss}>
      <div className="bg-white rounded-lg gap-4 flex flex-col shadow-lg p-6 min-w-[544px] max-w-[544px]">
        <div className="flex justify-end text-gray-200 hover:text-gray-300">
          <button onClick={handleDismiss}>
            <Icon type="x" />
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <CommonSpinner />
          </div>
        ) : !agent ? (
          <div className="flex flex-col items-center gap-6 py-8">
            <div className="text-center flex flex-col gap-1">
              <div className="font-gooper text-2xl font-semibold text-gray-800">
                Create Solo Agent
              </div>
              <div className="font-normal text-gray-500 max-w-md">
                Start by creating a Solo Agent. This is a live link that you can
                give out to people who want to interact with your Agent.
              </div>
            </div>

            {error && (
              <div className="text-red-500 text-sm text-center">{error}</div>
            )}

            <AnimatedButton
              title="Create Solo Agent"
              onClick={handleCreate}
              buttonState={createState}
              setButtonState={setCreateState}
              style="action"
              key="create"
              leftIcon="plus"
              font="font-sans"
              id="createButton"
              classNameIn="w-48"
            />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row text-xs text-gray-400 gap-1 justify-end">
                  <span>Last saved</span>
                  <span>{formatDate(agent?.modifiedAt)}</span>
                  <span>{formatTime(agent?.modifiedAt)}</span>
                </div>
                {agent?.lastPublishedAt && (
                  <div className="flex flex-row text-xs text-gray-400 gap-1 justify-end">
                    <span>Published</span>
                    <span>{formatDate(agent.lastPublishedAt)}</span>
                    <span>{formatTime(agent.lastPublishedAt)}</span>
                  </div>
                )}
              </div>

              <div className="text-center flex flex-col gap-1 mt-4 mb-4">
                <div className="font-gooper text-2xl font-semibold text-gray-800">
                  Publish Solo Agent
                </div>
                <div className="font-normal text-gray-500">
                  Configure how your Solo Agent will appear to users. Press
                  publish to make these changes live
                </div>
              </div>
            </div>

            <CommonInputSegment
              title="Title"
              onChange={(value) => handleInputChange("title", value)}
              value={agent?.title ?? ""}
              placeholder="Enter agent title"
              id="agentTitle"
            />

            <CommonInputSegment
              title="Description"
              onChange={(value) => handleInputChange("description", value)}
              value={agent?.description ?? ""}
              placeholder="Describe what your agent does"
              id="agentDescription"
            />

            <HexColorInputSegment
              title="Theme Color"
              onChange={(value) => handleInputChange("themeColor", value)}
              value={agent?.themeColor}
              id="themeColor"
            />

            <HexColorInputSegment
              title="Title Color"
              onChange={(value) => handleInputChange("titleColor", value)}
              value={agent?.titleColor}
              id="titleColor"
            />

            <div className="flex flex-col gap-4">
              {error && (
                <div className="text-red-500 text-sm text-center">{error}</div>
              )}

              <div className="flex flex-row justify-between">
                <AnimatedButton
                  title="Cancel"
                  onClick={handleDismiss}
                  buttonState="ready"
                  style="normal"
                  key="cancel"
                  font="font-sans"
                  id="cancelButton"
                  classNameIn="w-40"
                />
                <AnimatedButton
                  title="Publish Agent"
                  onClick={handlePublish}
                  buttonState={publishState}
                  setButtonState={setPublishState}
                  style="action"
                  key="action"
                  leftIcon="paper-plane"
                  font="font-sans"
                  id="publishButton"
                  classNameIn="w-40"
                />
              </div>

              {agent?.published && (
                <a
                  href={`${window.location.origin}/agent/${agentId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-center text-sm text-blue-500 hover:text-blue-600 underline"
                >
                  {`Live @ https://www.app.easybeam.ai/agent/${agentId}`}
                </a>
              )}

              {showDeleteConfirm ? (
                <div className="mt-8 pt-4 border-t border-gray-200">
                  <div className="text-center mb-4">
                    <p className="text-gray-800 font-medium">
                      Delete this Solo Agent?
                    </p>
                    <p className="text-sm text-gray-500">
                      This action cannot be undone.
                    </p>
                  </div>
                  <div className="flex justify-center gap-3">
                    <button
                      onClick={() => setShowDeleteConfirm(false)}
                      className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800 transition-colors"
                    >
                      Cancel
                    </button>
                    <AnimatedButton
                      title="Delete"
                      onClick={handleDelete}
                      buttonState={deleteState}
                      setButtonState={setDeleteState}
                      style="destructive"
                      key="delete"
                      leftIcon="trash"
                      font="font-sans"
                      id="deleteButton"
                      classNameIn="w-32"
                    />
                  </div>
                </div>
              ) : (
                <div className="mt-8 pt-4 border-t border-gray-200 text-center">
                  <button
                    onClick={() => setShowDeleteConfirm(true)}
                    className="text-sm text-gray-400 hover:text-red-500 transition-colors"
                  >
                    Delete Solo Agent
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </CommonModal>
  );
};
