import React, { useState, useRef, useEffect } from "react";
import Icon, { IconType } from "../../Icon";
import { useReactFlow } from "reactflow";
import {
  ConversationalDataContext,
  findAvailableConversationalData,
  findAvailableOutputNodes,
} from "../Map/Nodes/NodeUtils";

export type TextInput = {
  type: "text";
  value?: string;
};

export type OutputInput = {
  type: "output";
  nodeId?: string;
};

export type VariableInput = {
  type: "variable";
  variableId?: string;
};

export type ConversationalInput = {
  type: "conversational";
  requirementId?: string;
  nodeId?: string;
  name?: string;
};

export type MessagesInput = {
  type: "messages";
  messageType: "mostRecent";
};

export type SelectedInput =
  | TextInput
  | OutputInput
  | VariableInput
  | ConversationalInput
  | MessagesInput;

interface VariableInputSelectorProps {
  availableVariables: string[];
  selectedInput: SelectedInput;
  setSelectedInput: (selectedInput: SelectedInput | undefined) => void;
  currentNodeId: string | undefined;
  id?: string;
  includeMessages?: boolean;
}

interface OutsideClickListenerProps {
  clickedOutside: () => void;
  children: React.ReactNode;
}

const OutsideClickListener: React.FC<OutsideClickListenerProps> = ({
  clickedOutside: setIsOpen,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div className="relative" ref={ref}>
      {children}
    </div>
  );
};

// TypeSelector component
const TypeSelector: React.FC<{
  selectedType: string;
  setSelectedInput: (selectedInput: SelectedInput) => void;
  hasConvoData: boolean;
  id?: string;
}> = ({ selectedType, setSelectedInput, hasConvoData, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const typeOptions: SelectedInput[] = [
    { type: "output" },
    { type: "text" },
    { type: "variable" },
    { type: "messages", messageType: "mostRecent" },
  ];

  if (hasConvoData) {
    typeOptions.push({ type: "conversational" });
  }

  const iconType = (): IconType => {
    if (selectedType == "variable") {
      return "workflows";
    } else if (selectedType == "text") {
      return "quote";
    } else if (selectedType == "output") {
      return "reply-all";
    } else if (selectedType == "messages") {
      return "messages";
    } else {
      return "arrow-up-down";
    }
  };

  const title = (): string => {
    if (selectedType == "variable") {
      return "Variables";
    } else if (selectedType == "text") {
      return "Static Text";
    } else if (selectedType == "output") {
      return "Output";
    } else if (selectedType == "messages") {
      return "Messages";
    } else {
      return "Conversational";
    }
  };

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        id={id}
        className="h-10 w-full px-3 py-2 bg-gray-50 rounded-l-lg border-l border-t border-b border-gray-300 flex items-center justify-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-row gap-1 items-center">
          <Icon
            type={iconType()}
            className={`text-gray-400 size-5 ${
              selectedType == "conversational" ? "rotate-90" : ""
            }`}
          />
          <div className="text-gray-700 text-sm font-normal font-gooper truncate">
            {title()}
          </div>
        </div>
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } transition-all text-gray-400`}
        />
      </div>
      {isOpen && (
        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
          {typeOptions.map((typeOption) => (
            <div
              id={`${typeOption.type}Selector`}
              key={typeOption.type}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm font-normal font-['Gooper Trial SemiCondensed'] truncate"
              onClick={() => {
                setSelectedInput(typeOption);
                setIsOpen(false);
              }}
            >
              {typeOption.type}
            </div>
          ))}
        </div>
      )}
    </OutsideClickListener>
  );
};

// TextInput component
const TextInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  id: string | undefined;
}> = ({ value, onChange }) => (
  <input
    className="h-10 placeholder:font-normal border-gray-300 w-full font-medium bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm"
    value={value}
    onChange={(e) => onChange(e.target.value)}
    type="text"
    placeholder="text..."
    id="textInput"
    autoComplete="off"
  />
);

const OutputInput: React.FC<{
  selectedNodeId: string | undefined;
  currentNodeId: string | undefined;
  setSelectedInput: (input: OutputInput) => void;
  id: string | undefined;
}> = ({ selectedNodeId, currentNodeId, setSelectedInput, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getNodes, getEdges } = useReactFlow();

  const nodes = getNodes();
  const edges = getEdges();

  const outputNodes = findAvailableOutputNodes(
    currentNodeId ?? "",
    nodes,
    edges
  );

  const selectedNode = nodes.find((n) => n.id === selectedNodeId);
  const isEmpty = !selectedNode;

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        id={`${id}-input`}
        onClick={() => setIsOpen(true)}
        className="h-10 relative border-gray-300 w-full bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm flex items-center justify-between cursor-pointer"
      >
        {isEmpty ? (
          <div className={`text-gray-500 truncate items-center justify-center`}>
            select beam
          </div>
        ) : (
          <div
            className={`truncate text-sx font-medium text-gray-700 p-1 items-center justify-center bg-gray-50 rounded-lg`}
          >
            {selectedNode.data.title}
          </div>
        )}

        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
        {isOpen && (
          <div className="absolute left-0 right-0 top-10 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            {outputNodes.map((node, index) => (
              <div
                id={`output${index}`}
                key={node.id}
                className="px-3 py-2 text-gray-700 hover:bg-green-100 cursor-pointer text-sm font-normal font-gooper truncate"
                onClick={(e) => {
                  setSelectedInput({
                    type: "output",
                    nodeId: node.id,
                  });
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              >
                {node.data.title}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickListener>
  );
};

// VariableInput component (updated with chevron)
const VariableInput: React.FC<{
  selectedVariableId: string | undefined;
  availableVariables: string[];
  setSelectedInput: (input: VariableInput) => void;
  id: string | undefined;
}> = ({ selectedVariableId, availableVariables, setSelectedInput, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isEmpty = selectedVariableId == undefined;

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        id={`${id}-input`}
        onClick={() => setIsOpen(true)}
        className="h-10 relative border-gray-300 w-full bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm flex items-center justify-between cursor-pointer"
      >
        {isEmpty ? (
          <div className={`text-gray-500 truncate items-center justify-center`}>
            variable...
          </div>
        ) : (
          <div
            className={`truncate text-sx font-medium text-gray-700 py-1 px-2 items-center justify-center bg-gray-50 rounded-lg`}
          >
            {selectedVariableId}
          </div>
        )}
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
        {isOpen && (
          <div className="absolute left-0 right-0 top-10 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            {availableVariables.map((variable) => (
              <div
                id={`variable${variable}`}
                key={variable}
                className="px-3 py-2 text-gray-700 hover:bg-green-100 cursor-pointer text-sm font-normal font-gooper truncate"
                onClick={(e) => {
                  setSelectedInput({
                    type: "variable",
                    variableId: variable,
                  });
                  setIsOpen(false);
                  e.stopPropagation();
                }}
              >
                {variable}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickListener>
  );
};

// Main VariableInputSelector component
const VariableInputSelector: React.FC<VariableInputSelectorProps> = ({
  availableVariables,
  selectedInput,
  setSelectedInput,
  currentNodeId,
  id,
}) => {
  const { getNodes, getEdges } = useReactFlow();
  const availableConversationalData = findAvailableConversationalData(
    currentNodeId ?? "",
    getNodes(),
    getEdges()
  );
  const renderInput = () => {
    switch (selectedInput.type) {
      case "messages":
        return (
          <MessagesInput
            selectedMessageType={selectedInput.messageType}
            setSelectedInput={(input) => setSelectedInput(input)}
            id={id}
          />
        );
      case "text":
        return (
          <TextInput
            value={selectedInput.value || ""}
            onChange={(value) => setSelectedInput({ type: "text", value })}
            id={`${id}-input`}
          />
        );
      case "output":
        return (
          <OutputInput
            selectedNodeId={selectedInput.nodeId}
            currentNodeId={currentNodeId}
            setSelectedInput={(input) => setSelectedInput(input)}
            id={id}
          />
        );
      case "variable":
        return (
          <VariableInput
            selectedVariableId={selectedInput.variableId}
            availableVariables={availableVariables}
            setSelectedInput={(input) => setSelectedInput(input)}
            id={id}
          />
        );
      case "conversational":
        return (
          <ConversationalInput
            selectedRequirementId={selectedInput.requirementId}
            availableData={availableConversationalData}
            setSelectedInput={(input) => setSelectedInput(input)}
            id={id}
          />
        );
    }
  };

  return (
    <div className="grid grid-cols-2">
      <TypeSelector
        id={id}
        selectedType={selectedInput.type}
        setSelectedInput={setSelectedInput}
        hasConvoData={availableConversationalData.length > 0}
      />
      {renderInput()}
    </div>
  );
};

const ConversationalInput: React.FC<{
  selectedRequirementId: string | undefined;
  availableData: ConversationalDataContext[];
  setSelectedInput: (input: ConversationalInput) => void;
  id: string | undefined;
}> = ({ selectedRequirementId, availableData, setSelectedInput, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedRequirement = availableData
    .flatMap((data) => data.requirements)
    .find((req) => req.id === selectedRequirementId);

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        id={`${id}-input`}
        onClick={() => setIsOpen(true)}
        className="h-10 relative border-gray-300 w-full bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm flex items-center justify-between cursor-pointer"
      >
        {!selectedRequirement ? (
          <div className="text-gray-500 truncate items-center justify-center">
            select requirement...
          </div>
        ) : (
          <div className="truncate text-sx font-medium text-gray-700 py-1 px-2 items-center justify-center bg-gray-50 rounded-lg">
            {selectedRequirement.name}
          </div>
        )}
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
        {isOpen && (
          <div className="absolute left-0 right-0 top-10 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            {availableData.map((context, index) => (
              <div
                id={`conversationalData${index}`}
                key={context.actionId}
                className="border-b border-gray-100 last:border-b-0"
              >
                {context.requirements.map((requirement) => (
                  <div
                    key={requirement.id}
                    className="px-3 py-2 text-gray-700 hover:bg-green-100 cursor-pointer text-sm font-normal font-gooper"
                    onClick={(e) => {
                      setSelectedInput({
                        type: "conversational",
                        requirementId: requirement.id,
                        nodeId: context.actionNodeId,
                        name: requirement.name,
                      });
                      setIsOpen(false);
                      e.stopPropagation();
                    }}
                  >
                    <div className="font-medium">{requirement.name}</div>
                    <div className="text-xs text-gray-500 mt-0.5 truncate">
                      {requirement.description}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickListener>
  );
};

const MessagesInput: React.FC<{
  selectedMessageType: string | undefined;
  setSelectedInput: (input: MessagesInput) => void;
  id: string | undefined;
}> = ({ selectedMessageType, setSelectedInput, id }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <OutsideClickListener clickedOutside={() => setIsOpen(false)}>
      <div
        id={`${id}-input`}
        onClick={() => setIsOpen(true)}
        className="h-10 relative border-gray-300 w-full bg-gray-0 text-gray-700 border rounded-r-lg p-2 leading-tight text-sm flex items-center justify-between cursor-pointer"
      >
        {!selectedMessageType ? (
          <div className="text-gray-500 truncate items-center justify-center">
            select message...
          </div>
        ) : (
          <div className="truncate text-sx font-medium text-gray-700 py-1 px-2 items-center justify-center bg-gray-50 rounded-lg">
            Most Recent Message
          </div>
        )}
        <Icon
          type="chevron-up"
          className={`flex-shrink-0 ml-2 ${
            isOpen ? "rotate-180" : ""
          } text-gray-400 transition-all`}
        />
        {isOpen && (
          <div className="absolute left-0 right-0 top-10 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
            <div
              className="px-3 py-2 text-gray-700 hover:bg-green-100 cursor-pointer text-sm font-normal font-gooper"
              onClick={() => {
                setSelectedInput({
                  type: "messages",
                  messageType: "mostRecent",
                });
                setIsOpen(false);
              }}
            >
              Most Recent Message
            </div>
          </div>
        )}
      </div>
    </OutsideClickListener>
  );
};

export default VariableInputSelector;
