import React, { useState } from "react";
import { CommonModal } from "../Common/CommonModal";
import AnimatedButton, { AnimationState } from "../AnimatedButton";
import { useKnowledgeService } from "../../contexts/KnowledgeContext";
import { WebsiteFilterOptions } from "../../models/Workflow";

export const ENCODING_STRATEGIES = {
  precise: {
    chunkSize: 256,
    chunkOverlap: 64,
    label: "Precise",
    description:
      "Best for technical documentation and code. Uses smaller chunks for accurate retrieval.",
  },
  balanced: {
    chunkSize: 512,
    chunkOverlap: 128,
    label: "Balanced",
    description:
      "Good for general content like articles and blog posts. Maintains a balance between context and precision.",
  },
  contextHeavy: {
    chunkSize: 1024,
    chunkOverlap: 256,
    label: "Context-Heavy",
    description:
      "Ideal for narrative content and complex explanations. Preserves more surrounding context.",
  },
} as const;

type StrategyKey = keyof typeof ENCODING_STRATEGIES;

interface AddWebsiteModalProps {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface StrategyButtonProps {
  strategyKey: StrategyKey;
  strategy: (typeof ENCODING_STRATEGIES)[StrategyKey];
  isSelected: boolean;
  onClick: () => void;
}

const StrategyButton: React.FC<StrategyButtonProps> = ({
  strategy,
  isSelected,
  onClick,
}) => (
  <button
    onClick={onClick}
    className={`flex flex-col p-4 rounded-lg border-2 transition-all w-full
      ${
        isSelected
          ? "border-blue-500 bg-blue-50"
          : "border-gray-200 hover:border-gray-300 bg-white"
      }`}
  >
    <span className="font-medium text-left mb-1">{strategy.label}</span>
    <span className="text-sm text-gray-600 text-left">
      {strategy.description}
    </span>
  </button>
);

export const AddWebsiteModal: React.FC<AddWebsiteModalProps> = ({
  teamId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const knowledgeService = useKnowledgeService();
  const [url, setUrl] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [buttonState, setButtonState] = useState<AnimationState>("ready");
  const [selectedStrategy, setSelectedStrategy] =
    useState<StrategyKey>("balanced");

  const handleSubmit = async () => {
    if (!url.trim()) {
      setError("Please enter a valid URL");
      return;
    }

    setButtonState("loading");
    try {
      const filterOptions: WebsiteFilterOptions = {
        removeScripts: true,
        removeStyles: true,
        removeNavigation: true,
        removeFooter: true,
        removeAds: true,
        keepMainContent: true,
        customSelectors: [],
      };

      const { chunkSize, chunkOverlap, label } =
        ENCODING_STRATEGIES[selectedStrategy];

      await knowledgeService.addWebsiteKnowledge(teamId, {
        url: url.trim(),
        filterOptions,
        encoding: {
          chunkSize,
          chunkOverlap,
          name: label,
        },
      });

      setButtonState("ready");
      onSuccess();
      onClose();
    } catch (e) {
      setError(e instanceof Error ? e.message : "Failed to add website");
      setButtonState("error");
    }
  };

  return (
    <CommonModal isOpen={isOpen} onDismiss={onClose}>
      <div className="p-6 w-[48rem]">
        <h2 className="text-xl font-gooper font-semibold mb-4">Add Website</h2>
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Website URL
            </label>
            <input
              type="url"
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              placeholder="https://example.com"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
                setError(null);
              }}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Encoding Strategy
            </label>
            <div className="grid grid-cols-3 gap-4">
              {(
                Object.entries(ENCODING_STRATEGIES) as [
                  StrategyKey,
                  (typeof ENCODING_STRATEGIES)[StrategyKey]
                ][]
              ).map(([key, strategy]) => (
                <StrategyButton
                  key={key}
                  strategyKey={key}
                  strategy={strategy}
                  isSelected={selectedStrategy === key}
                  onClick={() => setSelectedStrategy(key)}
                />
              ))}
            </div>
          </div>

          {error && <p className="text-sm text-red-600">{error}</p>}
        </div>

        <div className="flex justify-between gap-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Cancel
          </button>
          <AnimatedButton
            title="Add Website"
            onClick={handleSubmit}
            buttonState={buttonState}
            setButtonState={setButtonState}
            style="action"
            error={error}
            setError={setError}
            triggerKey="Enter"
          />
        </div>
      </div>
    </CommonModal>
  );
};
