import React, { useEffect, useState } from "react";
import { WorkflowDemoKnowledgeResult } from "../../../../../models/WorkflowDemoResult";
import { StatusBadge } from "../../../../TestCenter/StatusBadge";
import { CommonModal } from "../../../../Common/CommonModal";
import Icon from "../../../../Icon";
import { useKnowledgeService } from "../../../../../contexts/KnowledgeContext";
import { WebsiteKnowledge } from "../../../../../models/WebsiteKnowledge";

export const KnowledgeResultModal: React.FC<{
  result: WorkflowDemoKnowledgeResult;
  shows: boolean;
  onClose: () => void;
  teamId: string;
}> = ({ result, shows, onClose, teamId }) => {
  const knowledgeService = useKnowledgeService();
  const [websiteDetails, setWebsiteDetails] = useState<
    Record<string, WebsiteKnowledge>
  >({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWebsiteDetails = async () => {
      try {
        setIsLoading(true);
        const websites = await knowledgeService.websiteKnowledgeRepo.getList(
          knowledgeService.websiteKnowledgePath(teamId)
        );

        const websiteMap = websites.reduce((acc, website) => {
          acc[website.id] = website;
          return acc;
        }, {} as Record<string, WebsiteKnowledge>);

        setWebsiteDetails(websiteMap);
      } catch (error) {
        console.error("Error fetching website details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (shows) {
      fetchWebsiteDetails();
    }
  }, [shows, teamId, knowledgeService]);

  const websiteIds = result.usedWebsiteKnowledgeIds?.map((id) => id.trim());

  return (
    <CommonModal isOpen={shows} onDismiss={onClose}>
      <div className="p-6 max-w-2xl w-full relative">
        <div className="flex flex-row justify-end pb-2">
          <button
            className="text-gray-200 hover:text-gray-300 pt-8 pr-6"
            onClick={onClose}
          >
            <Icon type="x" className="size-5" />
          </button>
        </div>

        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">{result.name}</h2>
          <StatusBadge status={result.status} />
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Queried sources
            </h3>
            <div className="text-sm text-gray-600">
              {isLoading ? (
                <div className="animate-pulse bg-gray-100 h-20 rounded-md" />
              ) : (
                websiteIds?.map((id, index) => (
                  <div key={index} className="mb-2 p-2 bg-gray-50 rounded-md">
                    <div className="font-medium">
                      {websiteDetails[id]?.title || "Unknown Source"}
                    </div>
                    <div className="text-xs text-gray-500">
                      {websiteDetails[id]?.url || id}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Usage Metrics
            </h3>
            <div className="bg-gray-50 p-4 rounded-lg grid grid-cols-2 gap-4">
              <div>
                <div className="text-xs text-gray-500">Total Chunks Used</div>
                <div className="text-lg font-medium">
                  {result.usedChunks || 0}
                </div>
              </div>
              <div>
                <div className="text-xs text-gray-500">Sources Used</div>
                <div className="text-lg font-medium">{websiteIds?.length}</div>
              </div>
            </div>
          </div>

          {result.processedContent && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Retrieved Content
              </h3>
              <div className="bg-gray-50 p-4 rounded-lg text-sm whitespace-pre-wrap overflow-auto max-h-72">
                {result.processedContent}
              </div>
            </div>
          )}

          {result.error && (
            <div className="text-red-500 text-sm">Error: {result.error}</div>
          )}
        </div>
      </div>
    </CommonModal>
  );
};
