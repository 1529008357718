import { Edge, Node } from "reactflow";
import { APINodeType } from "./APINode";
import { PortalNodeType } from "./PortalNode";
import { SearchNodeType } from "./SearchNode";
import { WebsiteNodeType } from "./WebsiteNode";
import { ActionNodeData, ActionNodeType } from "./ActionNode";

import { ConversationalRequirement } from "../../../../models/WorkflowAction";
import { KnowledgeNodeType } from "./KnowledgeNode";

export interface ConversationalDataContext {
  actionId: string;
  actionNodeId: string;
  requirements: ConversationalRequirement[];
}

export function findAvailableConversationalData(
  targetNodeId: string,
  nodes: Node[],
  edges: Edge[]
): ConversationalDataContext[] {
  const visitedNodes = new Set<string>();
  const availableData: ConversationalDataContext[] = [];
  const nodeMap = new Map(nodes.map((node) => [node.id, node]));

  function traverse(nodeId: string) {
    if (visitedNodes.has(nodeId)) return;
    visitedNodes.add(nodeId);

    // Get incoming edges to this node
    const incomingEdges = edges.filter((edge) => edge.target === nodeId);

    for (const edge of incomingEdges) {
      // Check if this edge is from an action branch
      const parts = edge.id.split("_");
      if (parts.length >= 3 && parts[5]?.startsWith("ACTION:")) {
        const sourceNodeId = "node_" + parts[3].slice(0, -4);
        const actionId = "action_" + parts[6];
        const sourceNode = nodeMap.get(sourceNodeId);
        if (
          sourceNode?.data.type === ActionNodeType &&
          sourceNode?.data.actions
        ) {
          const action = (sourceNode.data as ActionNodeData).actions.find(
            (a) => a.id === actionId
          );

          if ((action?.conversationalRequirements?.length ?? 0) > 0) {
            availableData.push({
              actionId: actionId,
              actionNodeId: sourceNodeId,
              requirements: action?.conversationalRequirements ?? [],
            });
          }
        }
      }

      // Continue traversing up the graph
      traverse(edge.source);
    }
  }

  traverse(targetNodeId);
  return availableData;
}

// Extend the existing findAvailableOutputNodes function
export type OutputNodeType =
  | typeof PortalNodeType
  | typeof WebsiteNodeType
  | typeof SearchNodeType
  | typeof APINodeType
  | typeof ActionNodeType
  | typeof KnowledgeNodeType;

export const OUTPUT_NODE_TYPES: OutputNodeType[] = [
  PortalNodeType,
  WebsiteNodeType,
  SearchNodeType,
  APINodeType,
  ActionNodeType,
  KnowledgeNodeType,
];

export function findAvailableOutputNodes(
  targetNodeId: string,
  nodes: Node[],
  edges: Edge[],
  outputTypes: OutputNodeType[] = OUTPUT_NODE_TYPES
): Node[] {
  const visitedNodes = new Set<string>();
  const validNodes: Node[] = [];
  const nodeMap = new Map(nodes.map((node) => [node.id, node]));

  function traverse(nodeId: string) {
    if (visitedNodes.has(nodeId)) return;
    visitedNodes.add(nodeId);

    const node = nodeMap.get(nodeId);
    if (
      node &&
      outputTypes.includes(node.data.type as OutputNodeType) &&
      node.id !== targetNodeId
    ) {
      validNodes.push(node);
    }

    const incomingEdges = edges.filter((edge) => edge.target === nodeId);
    for (const edge of incomingEdges) {
      traverse(edge.source);
    }
  }

  traverse(targetNodeId);
  return validNodes;
}
