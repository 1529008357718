import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Workflow, WorkflowVersion } from "../../../models/Workflow";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  Node,
  ReactFlowInstance,
  Connection,
  Background,
  BackgroundVariant,
  Edge,
} from "reactflow";

import "reactflow/dist/style.css";
import {
  DecisionNode,
  DecisionNodeData,
  DecisionNodeType,
} from "./Nodes/DecisionNode";
import { PortalNodeData, PortalNode, PortalNodeType } from "./Nodes/PortalNode";
import {
  createStartNode,
  StartNode,
  StartNodeData,
  StartNodeType,
} from "./Nodes/StartNode";
import {
  createSelectorNode,
  SelectorNode,
  SelectorNodeType,
} from "./Nodes/SelectorNode";
import {
  WorkflowAddNodeEdge,
  WorkflowAddNodeEdgeType,
} from "./Edges/WorkflowAddNodeEdge";
import { useDebounce } from "../../../utils/Debounce";
import {
  WebsiteNode,
  WebsiteNodeData,
  WebsiteNodeType,
} from "./Nodes/WebsiteNode";
import { SearchNode, SearchNodeData, SearchNodeType } from "./Nodes/SearchNode";
import {
  WorkflowNode,
  WorkflowNodeData,
  WorkflowNodeType,
} from "./Nodes/WorkflowNode";
import { parseDates } from "../../../utils/DateUtils";
import { APINode, APINodeData, APINodeType } from "./Nodes/APINode";
import { ActionNode, ActionNodeData, ActionNodeType } from "./Nodes/ActionNode";

import { ContextMenu } from "./ContextMenu";
import {
  KnowledgeNode,
  KnowledgeNodeData,
  KnowledgeNodeType,
} from "./Nodes/KnowledgeNode";

export type SelectedStartNode = {
  type: string;
};

export type SelectableNodeData =
  | StartNodeData
  | PortalNodeData
  | DecisionNodeData
  | WebsiteNodeData
  | SearchNodeData
  | WorkflowNodeData
  | APINodeData
  | ActionNodeData
  | KnowledgeNodeData;

export const WorkflowMap: React.FC<{
  workflow: Workflow;
  saveMap: (ui: string) => void;
  selectedNode: (data: SelectableNodeData | undefined) => void;
  teamId: string;
  version: WorkflowVersion;
}> = ({ workflow, teamId, selectedNode, saveMap, version }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const versionRef = useRef<WorkflowVersion>();
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    nodeId: string;
  } | null>(null);

  // Track history for undo/redo
  const historyRef = useRef<{
    past: { nodes: Node[]; edges: Edge[] }[];
    future: { nodes: Node[]; edges: Edge[] }[];
  }>({
    past: [],
    future: [],
  });

  const nodeTypes = useMemo(
    () => ({
      [StartNodeType]: StartNode,
      [SelectorNodeType]: SelectorNode,
      [PortalNodeType]: PortalNode,
      [DecisionNodeType]: DecisionNode,
      [WebsiteNodeType]: WebsiteNode,
      [SearchNodeType]: SearchNode,
      [WorkflowNodeType]: WorkflowNode,
      [APINodeType]: APINode,
      [ActionNodeType]: ActionNode,
      [KnowledgeNodeType]: KnowledgeNode,
    }),
    []
  );

  const edgeTypes = useMemo(
    () => ({
      [WorkflowAddNodeEdgeType]: WorkflowAddNodeEdge,
      default: WorkflowAddNodeEdge,
    }),
    []
  );

  const instanceRef = useRef<ReactFlowInstance>();
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // Save current state to history before changes
  const saveToHistory = useCallback(() => {
    historyRef.current.past.push({
      nodes: nodesRef.current,
      edges: edgesRef.current,
    });
    // Limit history size
    if (historyRef.current.past.length > 50) {
      historyRef.current.past.shift();
    }
    // Clear future when new changes are made
    historyRef.current.future = [];
  }, []);

  const handleUndo = useCallback(() => {
    const { past } = historyRef.current;
    if (past.length === 0) return;

    const previousState = past.pop()!;
    historyRef.current.future.push({
      nodes: nodesRef.current,
      edges: edgesRef.current,
    });

    setNodes(previousState.nodes);
    setEdges(previousState.edges);
  }, [setNodes, setEdges]);

  const handleRedo = useCallback(() => {
    const { future } = historyRef.current;
    if (future.length === 0) return;

    const nextState = future.pop()!;
    historyRef.current.past.push({
      nodes: nodesRef.current,
      edges: edgesRef.current,
    });

    setNodes(nextState.nodes);
    setEdges(nextState.edges);
  }, [setNodes, setEdges]);

  // Handle keyboard shortcuts
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "z") {
        event.preventDefault();
        if (event.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [handleUndo, handleRedo]);

  const onInit = (rfInstance: ReactFlowInstance) => {
    instanceRef.current = rfInstance;
  };

  const updateNode = (nodeId: string, newData: any) => {
    saveToHistory();
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === nodeId) {
          return { ...node, data: { ...node.data, ...newData } };
        }
        return node;
      })
    );
  };

  useEffect(() => {
    nodesRef.current = nodes;
    edgesRef.current = edges;
  }, [nodes, edges]);

  useEffect(() => {
    if (versionRef.current?.id == version.id) {
      return;
    }
    versionRef.current = version;
    if (version.uiDataString) {
      const flow = parseDates(JSON.parse(version.uiDataString));
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
    } else {
      setNodes([createStartNode(teamId, workflow)]);
    }
  }, [version, workflow, teamId, setNodes, setEdges]);

  useEffect(() => {
    sendNodeData();
    debounceUpdate();
  });

  const onElementClick = (_: React.MouseEvent, node: Node) => {
    sendNodeData();
    updateNode(node.id, { hasProblem: undefined });
  };

  const sendNodeData = () => {
    const selectedNodes = nodes.filter((node) => node.selected);
    const node = selectedNodes[0];
    if (node?.data?.type == PortalNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == DecisionNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == WebsiteNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == SearchNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == WorkflowNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == StartNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == APINodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == ActionNodeType) {
      selectedNode(node.data);
    } else if (node?.data?.type == KnowledgeNodeType) {
      selectedNode(node.data);
    } else {
      selectedNode(undefined);
    }
  };

  const getDataString = (nodes: Node[], edges: Edge[]): string => {
    const sortedNodes = [...nodes].sort((a, b) => a.id.localeCompare(b.id));
    const nodesDataString = JSON.stringify(
      sortedNodes.map((node) => node.data)
    );
    const sortedEdges = [...edges].sort((a, b) => a.id.localeCompare(b.id));
    const sortedEdgesDatString = JSON.stringify(
      sortedEdges.map((edge) => edge.source + edge.target)
    );

    return `nodes: ${nodesDataString} edges: ${sortedEdgesDatString}`;
  };

  const debounceUpdate = useDebounce(() => {
    const flow = JSON.parse(version.uiDataString ?? "{}");
    const newFlow = instanceRef.current!.toObject();
    const newNodes = newFlow.nodes;
    const newEdges = newFlow.edges;
    const oldNodes = flow.nodes ?? [];
    const oldEdges = flow.edges ?? [];

    const newData = getDataString(newNodes, newEdges);
    const oldData = getDataString(oldNodes, oldEdges);

    if (newData !== oldData && newNodes.length > 1) {
      const uiDataString = JSON.stringify(instanceRef.current?.toObject());
      saveMap(uiDataString);
    }
  }, 1000);

  const onPaneClick = () => {
    setNodes((nodes) => nodes.filter((n) => n.id != SelectorNodeType));
    selectedNode(undefined);
    setContextMenu(null); // Add this line
  };

  const onConnect = useCallback(
    (connection: Connection) => {
      saveToHistory();
      setEdges((eds) => addEdge(connection, eds));
    },
    [setEdges]
  );

  // Handle node changes with history
  const handleNodesChange = useCallback(
    (changes: any) => {
      if (changes.some((change: any) => change.type !== "select")) {
        saveToHistory();
      }
      onNodesChange(changes);
    },
    [onNodesChange]
  );

  // Handle edge changes with history
  const handleEdgesChange = useCallback(
    (changes: any) => {
      if (changes.some((change: any) => change.type !== "select")) {
        saveToHistory();
      }
      onEdgesChange(changes);
    },
    [onEdgesChange]
  );

  const onNodeContextMenu = (event: React.MouseEvent, node: Node) => {
    // Prevent default context menu
    event.preventDefault();

    // Don't show context menu for start node
    if (node.type === StartNodeType) {
      return;
    }

    // Calculate position for context menu
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      nodeId: node.id,
    });
  };

  // Add this new function inside the WorkflowMap component
  const handleDeleteNode = () => {
    if (!contextMenu) return;

    saveToHistory();

    // Delete all connected edges
    setEdges(
      edges.filter(
        (edge) =>
          edge.source !== contextMenu.nodeId &&
          edge.target !== contextMenu.nodeId
      )
    );

    // Delete the node
    setNodes(nodes.filter((node) => node.id !== contextMenu.nodeId));

    // Close context menu
    setContextMenu(null);

    // Clear node selection
    selectedNode(undefined);
  };

  const handleChangeType = () => {
    if (!contextMenu) return;

    const node = nodes.filter((node) => node.id == contextMenu.nodeId)[0];
    if (!node) return;

    // Create selector node for replacement
    const selectorNode = createSelectorNode(
      node,
      teamId,
      undefined,
      undefined,
      undefined,
      contextMenu.nodeId // Pass the ID of the node to replace
    );

    // Add selector node
    setNodes((nodes) => [...nodes, selectorNode]);

    // Close context menu
    setContextMenu(null);
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodesChange}
        onEdgesChange={handleEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeClick={onElementClick}
        onInit={onInit}
        onPaneClick={onPaneClick}
        onNodeContextMenu={onNodeContextMenu}
      >
        <Background color="#ccc" variant={BackgroundVariant.Dots} />
        <Controls />
        {contextMenu && (
          <ContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            onClose={() => setContextMenu(null)}
            onDelete={handleDeleteNode}
            onChangeType={handleChangeType}
          />
        )}
      </ReactFlow>
    </>
  );
};
